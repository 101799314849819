<template>
  
    <!-- TODO: Refactor the set price thing into its own component and listen for the new price -->
    <div class="modal modal-fade" tabindex="-1" role="dialog" id="itemsellmodal">
        <div class="modal-dialog modal-md modal-dialog-centered" role="document">
            <div class="modal-content" v-if="item">
                <div class="modal-header text-center">

                        <h5 class="modal-title col-12 text-center d-flex">
                        <div style="left: 0px; height: 0px; position: absolute; top: -13px;"><img :src="'https://steamcommunity-a.akamaihd.net/economy/image/' + item.image + '/256fx256f'" alt="" style="height:60px;"></div>
                        
                        <div class="px-2" style="margin-left:45px; margin-top:5px;" v-resize-text="{ 'ratio': 2.5 }">{{ item.market_hash_name }}</div>
                        
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        </h5>
                </div>
                <div class="modal-body p-3">
                    <div class="d-flex justify-content-center my-2">
                    <div class="d-flex">
                        <div class="input-group-prepend">
                            <span 
                                class="input-group-text brl" 
                                id="basic-addon1" 
                                style="
                                    font-size: 1.3rem;
                                    border-top-right-radius: 0px;
                                    border-bottom-right-radius: 0px;
                                "
                            >$</span>
                        </div>
                        <currency-input
                            :value="price"
                            @change="price = $event"
                            v-on:enter="$emit('price', price)"
                            class=""
                            style="
                                font-size: 1.3rem;
                                text-align: center;
                                border-top-right-radius: 15px; 
                                border-bottom-right-radius: 15px; outline: none;
                                border: 1px solid #ced4da;
                                background-color: #e9ecef;
                                color: #7BAA64;
                            "
                            :options="{
                                currency: 'USD',
                                currencyDisplay: 'hidden',
                                hideCurrencySymbolOnFocus: false,
                                hideGroupingSeparatorOnFocus: false,
                                hideNegligibleDecimalDigitsOnFocus: false,
                                useGrouping: true,
                                valueRange: { min: 0, max: 100000 },
                            }"
                        />
                    </div>
                    <button class="btn btn-orange ml-3" @click="$emit('price', price)" data-dismiss="modal">CONFIRM</button>
                    </div>

                    <div class="d-flex justify-content-around mt-3">
                    <div>
                        <div class="mx-1">
                        <div class="mb-2" style="border: 1px solid #606060; border-radius: 5px; padding: 5px 0px; background: #f3f3f3;">
                            <div style="font-family: Roboto; font-size: 0.8 rem;font-weight: bold; text-align: center;">Average Sale</div>
                            <div style="font-family: Roboto; font-size: 1.2rem; color: #7BAA64; font-weight: bold; text-align: center">{{numeral(item.avgPrice).format('$0,0.00')}}</div>
                        </div>
                        <button class="btn btn-block" @click="price =  item.avgPrice" :disabled="!item.avgPrice" :class="item.avgPrice ? 'btn-teal' : 'btn-dark'" >MATCH</button>
                        </div>
                    </div>
                    <div>
                        <div class="mx-1">
                        <div class="mb-2" style="border: 1px solid #606060; border-radius: 5px;  padding: 5px 0px; background: #f3f3f3;">
                            <div style="font-family: Roboto; font-size: 0.8 rem;font-weight: bold; text-align: center;">Lowest Listing</div>
                            <div style="font-family: Roboto; font-size: 1.2rem; color: #7BAA64; font-weight: bold; text-align: center">{{numeral(item.avgPrice).format('$0,0.00')}}</div>
                        </div>
                        <button class="btn btn-block" @click="price = item.avgPrice" :disabled="!item.avgPrice"  :class="item.avgPrice ? 'btn-teal' : 'btn-dark'">MATCH</button>
                        </div>
                    </div>
                    <div>
                        <div class="mx-1">
                        <div class="mb-2" style="border: 1px solid #606060; border-radius: 5px;  padding: 5px 0px; background: #f3f3f3;">
                            <div style="font-family: Roboto; font-size: 0.8 rem;font-weight: bold; text-align: center;">Highest Buyorder</div>
                            <div style="font-family: Roboto; font-size: 1.2rem; color: #7BAA64; font-weight: bold; text-align: center">{{numeral(item.avgPrice).format('$0,0.00')}}</div>
                        </div>
                        <button class="btn btn-block" @click="price = item.avgPrice" :disabled="!item.avgPrice" :class="item.avgPrice ? 'btn-teal' : 'btn-dark'" >MATCH</button>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const numeral = require('numeral');

export default {
    data() {
        return {
            item: null,
            price: 0
        }
    },
    methods: {
        numeral,
        open(item) {
            this.item = item;
            this.price = item.listPrice;
            // Open the modal
            $('#itemsellmodal').modal('show');
        },
        close() {
            this.item = null;
            $('#itemsellmodal').modal('hide');
        },
    }
}
</script>

<style>

</style>