<template>
  <div class="h-100">
    <div style="overflow-y: auto; background-color: #000000;" class="h-100">
      <div class style="background-color: #1D2021; ">
        <div class="container">
          <div class="d-flex justify-content-between py-3">
            <div>
              <input
                class="form-control text-white search-bar"
                type="text"
                name="Search Query"
                id="searchQuery"
                placeholder="Search My Inventory"
                v-model="searchQuery"
                @focus="clearPlaceholder"
                @blur="setPlaceholder"
              />
            </div>
            <div class="dropdown text-white my-auto">
              <a
                class="rubik hover-pointer"
                type="a"
                id="dropdownMenua"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span class="text-white font-weight-light mr-2">Game:</span>
                <span class="text-white font-weight-bold">{{game}} </span>
                <font-awesome-icon icon="caret-down" />
              </a>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" href="#0" @click="game = 'All'">All</a>
                <a class="dropdown-item" href="#0" @click="game = 'CS:GO'">CS:GO</a>
                <a class="dropdown-item" href="#0" @click="game = 'TF2'">TF 2</a>
                <a class="dropdown-item" href="#0" @click="game = 'Dota 2'">Dota 2</a>
                <a class="dropdown-item" href="#0" @click="game = 'Rust'">Rust</a>
              </div>
            </div>
            <div class="dropdown text-white my-auto">
              <a
                class="rubik hover-pointer"
                type="a"
                id="dropdownMenua"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span class="text-white font-weight-light mr-2">Sort By:</span>
                <span class="text-white font-weight-bold">{{sortOrderDisplay}} </span>
                <font-awesome-icon icon="caret-down" />
              </a>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" href="#0" @click="setSortOrder('nameAsc')">Name (Ascending)</a>
                <a class="dropdown-item" href="#0" @click="setSortOrder('nameDesc')">Name (Descending)</a>
                <a class="dropdown-item" href="#0" @click="setSortOrder('priceLow')">Price (Low to High ↑)</a>
                <a class="dropdown-item" href="#0" @click="setSortOrder('priceHigh')">Price (High to Low ↓)</a>
                <a class="dropdown-item" href="#0" @click="setSortOrder('rarity')">Rarity</a>
                <a class="dropdown-item" href="#0" @click="setSortOrder('floatLow')">Float (Low to High ↑)</a>
                <a class="dropdown-item" href="#0" @click="setSortOrder('floatHigh')">Float (High to Low ↓)</a>
              </div>
            </div>
            <div class="my-auto form-check form-check-inline">
              <label class="checkmark-container" style="width:80px; font-size: 14px; height: 20px">
                Select All
                <input type="checkbox" @click="selectAll" :checked="this.selectedItems.length == this.displayItems.flat().length"/>
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="my-auto">
              <button
                type="button"
                class="btn btn-primary"
                style="font-weight: normal; padding-left: 0; padding-right: 0; text-align: center; width: 200px;"
                @click="withdrawSelectedItems"
              >Withdraw {{selectedItems.length}} item(s)</button>
            </div>
            <div class="my-auto">
              <button
                type="button"
                class="btn btn-primary"
                style="font-weight: normal; padding-left: 0; padding-right: 0; text-align: center; width: 200px;"
                @click="relistSelectedItems"
              >Re-list {{selectedItems.length}} item(s)</button>
            </div>
          </div>
        </div>
      </div>

      <!-- <nav class="navbar" style="background-color: #1D2021;">
        <input
          class="mt-4 form-control"
          type="text"
          name="Search Query"
          id="searchQuery"
          placeholder="Search My Inventory"
          v-model="searchQuery"
          style="margin-left: 250px"
        />
        <button type="button" class="btn btn-light">Light</button>
        <button type="button" class="btn btn-light">Light</button>
        <button type="button" class="btn btn-light">Light</button>
        <button type="button" class="btn btn-light" style="margin-right: 250px">Light</button>
      </nav>-->

      <div class="container mt-2">
        <div class="row" v-for="(row, i) in displayItems" v-bind:key="i">
          <div class v-for="item in row" v-bind:key="item.id">
            <Item class="m-2" :item="item" :ref="'item-' + item.id" :withdrawable="true" @listingAdded="updateInventory" @selected="selectItem" @deselected="deselectItem" @relist="open(item.id)" overrideRelisting="true" ></Item>
          </div>
        </div>
      </div>
      </div>
      <ItemPriceSetter ref="priceSetter" @price="relist"/>

      <div class="modal modal-fade" tabindex="-1" role="dialog" id="sellmodal">
        <div class="modal-dialog modal-md modal-dialog-centered" role="document">
            <div class="modal-content"  v-if="selectedItem">
                <div class="modal-header text-center">

                      <h6 class="modal-title col-12 text-center d-flex">
                        <div style="left: 0px; height: 0px; position: absolute; top: -13px;"><img :src="'https://steamcommunity-a.akamaihd.net/economy/image/' + selectedItem.image + '/256fx256f'" alt="" style="height:60px;"></div>
                        <div class="px-2" style="margin-left:45px; margin-top:5px;" v-resize-text="{ 'ratio': 2.5 }">{{ selectedItem.market_hash_name }} ({{ selectedItemCount }})</div>

                        
                        <div class="item-tooltip-container">
                          <span class="item-name">
                            <div class="badge my-auto ml-2">
                              <img src="@/assets/info.svg" alt />
                            </div>
                          </span>
                          <Item class="item-tooltip" :item="selectedItem"></Item>
                        </div>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                      </h6>
                </div>
                <div class="modal-body p-3">
                  <div class="d-flex justify-content-center my-2">
                    <div class="d-flex">
                        <div class="input-group-prepend">
                            <span 
                                class="input-group-text brl" 
                                id="basic-addon1" 
                                style="
                                    font-size: 1.3rem;
                                    border-top-right-radius: 0px;
                                    border-bottom-right-radius: 0px;
                                "
                            >$</span>
                        </div>
                        <currency-input
                            :value="selectedItem.listPrice"
                            @change="setItemPrice(selectedItem.id, $event)"
                            v-on:enter="closeSellModal"
                            class=""
                            style="
                                font-size: 1.3rem;
                                text-align: center;
                                border-top-right-radius: 15px; 
                                border-bottom-right-radius: 15px; outline: none;
                                border: 1px solid #ced4da;
                                background-color: #e9ecef;
                                color: #7BAA64;
                            "
                            :options="{
                                currency: 'USD',
                                currencyDisplay: 'hidden',
                                hideCurrencySymbolOnFocus: false,
                                hideGroupingSeparatorOnFocus: false,
                                hideNegligibleDecimalDigitsOnFocus: false,
                                useGrouping: true,
                                valueRange: { min: 0, max: 100000 },
                            }"
                        />
                    </div>
                    <button class="btn btn-orange ml-3" @click="closeSellModal">CONFIRM</button>
                  </div>

                  <div class="d-flex justify-content-around mt-3">
                    <div>
                      <div class="mx-1">
                        <div class="mb-2" style="border: 1px solid #606060; border-radius: 5px; padding: 5px 0px; background: #f3f3f3;">
                          <div style="font-family: Roboto; font-size: 0.8 rem;font-weight: bold; text-align: center;">Average Sale</div>
                          <div style="font-family: Roboto; font-size: 1.2rem; color: #7BAA64; font-weight: bold; text-align: center">{{numeral(avgPrice).format('$0,0.00')}}</div>
                        </div>
                        <button class="btn btn-block" @click="changeListPrice(selectedItem, avgPrice);" :disabled="!avgPrice" :class="avgPrice ? 'btn-teal' : 'btn-dark'" >MATCH</button>

                      </div>
                    </div>
                    <div>
                      <div class="mx-1">
                        <div class="mb-2" style="border: 1px solid #606060; border-radius: 5px;  padding: 5px 0px; background: #f3f3f3;">
                          <div style="font-family: Roboto; font-size: 0.8 rem;font-weight: bold; text-align: center;">Lowest Listing</div>
                          <div style="font-family: Roboto; font-size: 1.2rem; color: #7BAA64; font-weight: bold; text-align: center">{{numeral(minPrice).format('$0,0.00')}}</div>
                        </div>
                        <button class="btn btn-block" @click="changeListPrice(selectedItem, minPrice);" :disabled="!minPrice" :class="minPrice ? 'btn-teal' : 'btn-dark'" >MATCH</button>
                      </div>
                    </div>
                    <div>
                      <div class="mx-1">
                        <div class="mb-2" style="border: 1px solid #606060; border-radius: 5px;  padding: 5px 0px; background: #f3f3f3;">
                          <div style="font-family: Roboto; font-size: 0.8 rem;font-weight: bold; text-align: center;">Highest Buyorder</div>
                          <div style="font-family: Roboto; font-size: 1.2rem; color: #7BAA64; font-weight: bold; text-align: center">{{ orderPrice ? numeral(orderPrice).format('$0,0.00') : 'N/a'}}</div>
                        </div>
                        <button class="btn btn-block" @click="changeListPrice(selectedItem, orderPrice);" :disabled="!orderPrice" :class="orderPrice ? 'btn-teal' : 'btn-dark'" >MATCH</button>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
    <Footer/>
  </div>
</template>

<script>
import api from "@/api.js";
const stringSimilarity = require('string-similarity');
import Swal from 'sweetalert2'
import { EventBus } from "../utils/eventBus.js";
import events from "@/events.js";
import ItemPriceSetter from "@/components/ItemPriceSetter";
import moment from "moment";
import numeral from 'numeral';

export default {
  data() {
    return {
      searchQuery: "",
      sortOrder: "",
      sortOrderDisplay: "None",
      selectedItems: [],
      priceSetterId: null,
      pollId: null,
      game: "All",
      selectedItemCount: 0,
      allPricesSet: false,
      nextItemIds: [],
      selectedItemId: null,

    };
  },
  components: {
    ItemPriceSetter
  },
  computed: {
    avgPrice() { return this.$refs['item-' + this.selectedItemId][0].avgPrice },
    minPrice() { return this.$refs['item-' + this.selectedItemId][0].minPrice },
    orderPrice() { return this.$refs['item-' + this.selectedItemId][0].orderPrice },
    selectedItem() {
      console.log("Recomputing")
      let selectedItem = this.items.find(x => x.id == this.selectedItemId)
      return selectedItem;
    },
    items(){
      return this.$store.getters["inventory/inventory"];
    },
    displayItems() {
      let seenCommodities = [];

      // let toRender = [];
      // for (let i = 0; i < 100; i++) {
      //   toRender.push(mock.item());
      // }
      let filteredItems = JSON.parse(JSON.stringify(this.items));
      let self = this;
      if (this.searchQuery && this.searchQuery.length > 2) {
        let search = this.searchQuery.toLowerCase()
        console.log("Searching using: " + search);
        // Split item name into words array and check if searchQuery is similar to  any of the words in array
        filteredItems = filteredItems.filter(function(item){
            let words = item.name.split(' ');
            // Removing vertical bar from array
            let bar = words.indexOf("|") 
            words.splice(bar, 1);

            for(let word of words){
              word = word.toLowerCase()
              if(stringSimilarity.compareTwoStrings(search, word) >= 0.4){
                return item;
              }
            }
        })
      }
      
    filteredItems = filteredItems.filter(function(item){ return item.state == "UN-LISTED" || item.state == "PENDING_WITHDRAW" })

      // Game filter
      switch(this.game) {
        case 'CS:GO':
          filteredItems = filteredItems.filter(x => x.appId === 730);
          break;
        case 'TF2':
          filteredItems = filteredItems.filter(x => x.appId === 440);
          break;
        case 'Dota 2':
          filteredItems = filteredItems.filter(x => x.appId === 570);
          break;
        case 'Rust':
          filteredItems = filteredItems.filter(x => x.appId === 252490);
          break;
      }

      let renderableItems = [];
      for (let item of filteredItems) {
        if (item.type == "COMMODITY" && seenCommodities.indexOf(item.market_hash_name) > -1) {
          continue; // Commodies hidden
        }

        renderableItems.push(item);
        seenCommodities.push(item.market_hash_name);
      }

      if (this.sortOrder) {
        let order = this.sortOrder;
        console.log("Sorting using: " + order);
        self.compareItemsUsingOrder(renderableItems, order)
      } 

      // console.log("Filtered items:");
      // for (let item of filteredItems) {
      //   console.log(item.name);
      // }
      const itemsPerRow = 4;
      // Do the 'binning' into groups of 4
      return renderableItems.reduce((col, item, i) => {
        if (i % itemsPerRow === 0) col.push([]); // Add a new row

        col[col.length - 1].push(item); // push item to the current row
        return col;
      }, []);
    }
  },
  async mounted() {
    await this.updateInventory()
    events.$on('withdrawalRetry', () => {
      this.withdrawSelectedItems();
    })
  },
  methods: {
    numeral,
    setItemPrice(itemId, listPrice) {
      console.log("Setting price");
      this.items.find(x => x.id == itemId).listPrice = listPrice;
      this.selectedItems.find(x => x.id == itemId).listPrice = listPrice;
      this.selectedItem.listPrice = listPrice;
    },
    openSellModal(itemId) {
      this.selectedItemId = itemId;
      this.selectedItemCount++;
      this.$nextTick(function(){ this.$forceUpdate() });
      // Open the modal
      $('#sellmodal').modal('show');
    },
    closeSellModal() {
      this.selectedItemId = null;
      console.log("clicked close");


      // Could refactor allPricesSet into a computed property with array length maybe.
      if (this.nextItemIds.length == 0) this.allPricesSet = true;
      

      if (this.nextItemIds.length > 0) {
        // We need to show the next
        console.log("showing next item");

        let itemId = this.nextItemIds.splice(0, 1)[0];

        // After splicing, are all prices set?
        if (this.nextItemIds.length == 0) {
          this.allPricesSet = true;
        }

        this.openSellModal(itemId);
        return;
      }
        
      console.log("Hiding modal")
      this.selectedItemId = null;
      $('#sellmodal').modal('hide');

      if (this.allPricesSet) {
        console.log("saving modal")
        this.relistSelectedItems();
      }
    },
    selectAll(){
      // TODO: only select filtered items
      let items = this.displayItems.flat()
      if(this.selectedItems.length != items.length){ // Select all
        this.selectedItems = items
        EventBus.$emit('selectedAllItemsInventory')
      } else { // De-Select all
        this.selectedItems = []
        EventBus.$emit('deselectedAllItemsInventory')
      }
    },
    deselectItem(id){
      let items = this.displayItems.flat()
      let found = items.findIndex(el => el.id == id)
      if(found >= 0){
        this.selectedItems = this.selectedItems.filter(el => el.id != id)
      }
    },
    selectItem(id){
      // Index of selectedItem has to be the same as filteredItems
      let items = this.displayItems.flat()
      let item = items.find(el => el.id == id);
      
      if (moment(item.lockedUntil).isValid() && moment(item.lockedUntil).isAfter()) {
        // debugger;
        this.$refs['item-'+item.id][0].deselect();
        return Swal.fire("Trade Locked", "Cannot withdraw tradelocked items. Please try again when the trade lock expires", "error");
      }

      this.selectedItems.push(item)
    },
    async updateInventory(){
      await this.$store.dispatch("inventory/updateInventory");
    },
    clearPlaceholder(e) {
      e.target.placeholder = "";
    },
    setPlaceholder(e) {
      e.target.placeholder = "Search My Inventory";
    },
    setSortOrder(sortOrder) {
      this.sortOrder = sortOrder;
      switch (sortOrder) {
        case "nameAsc":
          this.sortOrderDisplay = "Name (Ascending)";
          break;
        case "nameDesc":
          this.sortOrderDisplay = "Name (Descending)";
          break;
        case "priceLow":
          this.sortOrderDisplay = "Price (Low to High ↑)";
          break;
        case "priceHigh":
          this.sortOrderDisplay = "Price (High to Low ↓)";
          break;
        case "rarity":
          this.sortOrderDisplay = "Rarity";
          break;
        case "floatLow":
          this.sortOrderDisplay = "Float (Low to High ↑)";
          break;
        case "floatHigh":
          this.sortOrderDisplay = "Float (High to Low ↓)";
          break;
      }
    },
    convertRarity(rarity){
      switch(rarity){
        case "Consumer Grade":
          return 1;
        case "Industrial Grade":
          return 2;
        case "Mil-spec":
          return 3;
        case "Restricted":
          return 4;
        case "Classified ":
          return 5;
        case "Covert ":
          return 6;
        case "Exceedingly Rare ★":
          return 7;
        case "Contraband ":
          return 8;
        default:
          return 0;
      }
    },
    compareItemsUsingOrder(arr, order){
      let self = this;
      return arr.sort(function (a,b){
        if (order === "nameAsc") {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1;  }
          return 0;
        }
        else if (order === "nameDesc"){
          if (a.name < b.name) { return 1; }
          if (a.name > b.name) { return -1;  }
          return 0;
        }
        else if (order === "priceLow"){
          if (a.listPrice < b.listPrice) { return -1; }
          if (a.listPrice > b.listPrice) { return 1;  }
          return 0;
        }
        else if (order === "priceHigh"){
          if (a.listPrice < b.listPrice) { return 1; }
          if (a.listPrice > b.listPrice) { return -1;  }
          return 0;
        }
        else if (order === "rarity"){
          let rarA = self.convertRarity(a.rarity.name)
          let rarB = self.convertRarity(b.rarity.name)
          if (rarA < rarB) { return 1; }
          if (rarA > rarB) { return -1;  }
          return 0;
        }
        else if (order === "floatLow"){ // TODO: Make sure non-CSGO items aren't scuffing everything
          if (a.float < b.float) { return -1; }
          if (a.float > b.float) { return 1;  }
          return 0;
        }
        else if (order === "floatHigh"){ // TODO: Make sure non-CSGO items aren't scuffing everything
          if (a.float < b.float) { return 1; }
          if (a.float > b.float) { return -1;  }
          return 0;
        }
      });
    },
    async relistSelectedItems() {
      if (this.$store.getters["auth/user"].state == "HARD_BANNED") {
        return Swal.fire('Account Disabled', "Please contact support", 'danger');
      }

      if(this.selectedItems.length == 0){
        return Swal.fire('No items selected.', "Please selected some items to re-list.", 'error');
      } else {
        this.selectedItemCount = 0;
        if (!this.allPricesSet) {
          this.nextItemIds = JSON.parse(JSON.stringify(this.selectedItems.map(x => x.id)));
          return this.openSellModal(this.nextItemIds.splice(0, 1)[0]);
          }
        try {
        for (let item of this.selectedItems) {
          // debugger
          await this.$store.dispatch('items/updateListing', {
            partitionKey: item.depositerId,
            resourceId: item.id,
            changes: [{          
              key: "state",
              value: "LISTED",
              oldValue: "UN-LISTED"
            }, {          
              key: "listPrice",
              value: item.listPrice,
            }]
          });
        }

        
        Swal.fire({
          title: `Relisted`,
          text: `We've relisted ${this.selectedItems.length} items`,
          icon: "success",
          heightAuto: false,
        });

        this.selectedItems.splice(0, this.selectedItems.length)
        this.allPricesSet = false;

        // Removing items
        // let relistedItems = [...this.selectedItems];
        // for (let i = relistedItems.length - 1; i <= 0; i--){
        //   if (relistedItems[i].id === )
        // }
        this.$store.dispatch("inventory/updateInventory");
      } catch (e) {
        console.error(e)
        Swal.fire({
          title: `Error`,
          text: `There was an error while re-listing one or more item`,
          icon: "error",
          heightAuto: false,
        });
      }
      }
    },
    async withdrawSelectedItems() {
      if (this.$store.getters["auth/user"].state == "HARD_BANNED") {
        return Swal.fire('Account Disabled', "Please contact support", 'danger');
      }

      this.$store.dispatch('stepByStep/startWithdrawal');

      if(this.selectedItems.length == 0){
        this.$store.commit('stepByStep/setError', {
          retryable: false,
          message: `You have not selected any items!`,
        });
      } else {
        console.log("Got into else");
        try {
          let response = await api.withdrawItems(this.selectedItems);

          // this.$store.dispatch('stepByStep/clear');
          this.$store.commit('stepByStep/updateTradeoffers', response.data.tradeoffers);
          this.$store.commit('stepByStep/completeStep');
        } catch (e) {
          let error = `We failed to process some of your selected items.`;
          console.log(e.response);
          if (e.response && e.response.data && e.response.data.errors && e.response.data.errors[0])
            error = e.response.data.errors[0];

          if (e.response && e.response.data && e.response.data.error == "ValidationError") { 
            error = e.response.data.message;
          }
          
          // {
          //   "error": "ValidationError",
          //   "message": "A tradeoffer is already active for this item. Please cancel to continue"
          // }

          console.log("Sad times");
          console.error(e);
          this.$store.commit('stepByStep/setError', {
            retryable: true,
            retryEvent: "withdrawalRetry",
            message: error,
          });
        }
      }
    },
    async open(itemId) {
      this.priceSetterId = itemId;
      this.$refs.priceSetter.open(this.items.find(x => x.id == itemId))
    },
    close() {
      this.priceSetterId = null;
      this.$refs.priceSetter.close();
    },
    async relist(price) {
      try {
        Swal.showLoading({ heightAuto: false });
        let item = this.items.find(x => x.id == this.priceSetterId);
        if (!item) return console.error("Unable to find item! " + this.priceSetterId);
        console.log(this.$refs['item-'+item.id])
        this.$refs['item-'+item.id].unlistinglistingloader = true;
        this.close()
        console.log("Re-listing item")
        let params = {
          resourceId: item.id,
          changes: [{          
            key: "state",
            value: "LISTED",
            oldValue: "UN-LISTED"
          },{          
            key: "listPrice",
            value: price,
          }]
        }
        await this.$store.dispatch('items/updateListing', params);
        console.log("Relisted");
        this.$refs['item-'+item.id].unlistinglistingloader = false;
        item.state = "LISTED";
        Swal.close();
      } catch (e) {
        console.log(e);
        Swal.fire({ title: "Unable to relist", text: e.message, icon: 'error', heightAuto: false });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
input::placeholder {
  color: #d3d3d3;
}
input::placeholder {
  color: #d3d3d3;
  font-style: italic;
}
input:focus {
  background-color: #000000;
}
input {
  background-color: #000000;
  color: red;
  border: 0px;
  border-radius: 9px;
}
select {
  color: #d3d3d3;
  border-radius: 9px;
  border: 0px;
  background-color: #1d2021;
  font-family: "FontAwesome", "Rubik";
}
select:after {
  background-color: #1d2021;
}
select:focus {
  background-color: #1d2021;
  color: #d3d3d3;
}
select option {
  background-color: #1d2021;
  color: #d3d3d3;
}
.hover-pointer:hover{
    cursor: pointer;
}
.search-bar{
  background-color: #101010;
  border: 0px;
  border-radius: 10px;
}
.search-bar:focus{
  background-color: #101010;
}
.item-image {
  padding-bottom: 10px;
}

.item-name {
  min-width: 150px;
}
.item-title {
  max-width: 100%;
}

/* Tooltip container */
.item-tooltip-container {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; If you want dots under the hoverable text */
  cursor: pointer;
}

/* Tooltip text */
.item-tooltip-container .item-tooltip {
  visibility: hidden;
  /* width: 120px; */
  /* background-color: black; */
  /* color: #fff; */
  /* text-align: center; */
  /* padding: 5px 0; */
  /* border-radius: 6px; */
 
  /* Position the tooltip text - see examples below! */

  top: 0%;
  left: 0px; /* To the left of the tooltip */
  position: absolute;
  z-index: 1;
}

.item-tooltip-container:hover{
  border-bottom: 1px solid white; 

}
/* Show the tooltip text when you mouse over the tooltip container */
.item-tooltip-container:hover .item-tooltip {
  visibility: visible;
}
</style>